// 课题研究
<template>
  <div class="honour-topic">
    <el-table class="topic-table" :data="topics" stripe style="width: 100%" border>
      <el-table-column prop="name" label="项目委托单位" width="286" align="center">
      </el-table-column>
      <el-table-column prop="describe" label="课题名" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getCore } from '@/api'
export default {
  name: 'Topic',
  data () {
    return {
      topics: []
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getCore('ktyj')
      this.topics = data
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .topic-table {
  border-radius: 8px;
  &.el-table--border {
    border: 2px solid #fbd690;
    &::after {
      width: 0;
      background-color: transparent;
    }
    .el-table_1_column_1 {
      border-right: 2px solid #fbd690;
    }
  }
  .el-table__header {
    th {
      height: 56px;
      background-color: #fbd690;
      font-size: 18px;
      border: none;
    }
  }
  .el-table__body {
    td {
      height: 60px;
      font-size: 16px;
      border-bottom: none;
    }
    .el-table__row--striped {
      td {
        background-color: #f9f2e4;
      }
    }
  }
  td,
  th {
    font-weight: bold;
    color: #333333;
  }
}
</style>
